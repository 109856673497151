import pptxgen from 'pptxgenjs'
import docmaLogo from "../assets/docma-logo-white-red.svg"
import docmaBackground from "../assets/docma-headslide-background.png"

class PPTXBuilder{
    constructor(){
        this.pptx = new pptxgen();
        this.pptx_name = 'Docma';
        this.pptx_pages = []
    }
    set_slide(){
        this.pptx.defineSlideMaster({
            title: "docma_slide",
            background: {color: "#FFFFFF"},
            objects: [
                {text: {text: "", options: {x: 0, y: 0, w: "100%", h: '15%', fill: '#000000'}}},
                {image: {x: 8.4, y: "3%", w: 1.4,h: 0.35, path: docmaLogo}},
                { text: { text: "CONFIDENTIAL", options: { x:'0%', y:'95%', w:'100%', h:'5%', align:'center', fill:'#000000', color:'#ffffff', fontSize: 12} } },
                { text: { text: new Date().toString().slice(4, 16), options: { x:'5%', y:'95%', w:'100%', h:'5%', align:'left', color:'#ffffff', fontSize: 8 } } }
            ],
            slideNumber: { x: "90%", y: "95%", color:'#ffffff' }
        })
        this.pptx.defineSlideMaster({
            title: "docma_subheader_slide",
            objects: [
                {image: {x: 0, y: 0, w:'100%' ,h:'100%', path: docmaBackground}},
                {image: {x: 8.4, y: "3%", w: 1.4,h: 0.35, path: docmaLogo}},
                {text: { text: "CONFIDENTIAL", options: { x:'0%', y:'95%', w:'100%', h:'5%', align:'center', fill:'#000000', color:'#ffffff', fontSize: 12} } }
            ]
        })
    }
    get_name(){
        return this.pptx_name;
    }
    set_name(name){
        if(name != '' && name != null) {
            this.pptx_name = name;
        }
    }
    add_page(pageElement){
        this.pptx_pages.push(pageElement)
    }

    build(){
        this.set_slide();
        this.add_title_slide();

        this.pptx_pages.forEach((page) => {
            this.add_chart_slide(page);
        })
    }
    add_title_slide(){
        let title_slide = this.pptx.addSlide({masterName: "docma_subheader_slide"});
        title_slide.addText('Docma Charts Export',{x: '60%',y: '15%', w: "40%", h: 3, align: 'center', fontFace: 'Century Gothic', fontWeight: 'Bold', fontSize: 30, color: '#ffffff'});
    }
    add_chart_slide(pageElement){
        let slide = this.pptx.addSlide({masterName: "docma_slide"});
        slide.addText(pageElement['title'],
            {
                x:0 ,y:0,w:'100%',h:'15%',
                align: 'left', fontFace: 'Century Gothic', fontSize: 25, color:'#ffffff'
            }
        );

        let miniChartHeight = 0.5;
        let mainChartHeight = 3 - miniChartHeight * pageElement["miniCharts"].length;

        // main chart
        slide.addImage({data: pageElement["mainChart"]['image'], x: 0.25, y: 1.25, h: mainChartHeight, w: 6.5});
        let currentY = mainChartHeight + 1.25;

        // main chart indicators
        let indicatorCount = 0
        let indicatorY = 0
        for(let idx = pageElement["indicators"].length - 1; idx >= 0; idx--){
            indicatorY = currentY - 0.3 - (indicatorCount * 0.2)
            slide.addShape(this.pptx.shapes.ARC, { x: 5.75, y: indicatorY, w: 0.1, h: 0.1, fill: { color: pageElement["indicators"][idx]["metric_color"] }, angleRange: [0, 360] });
            slide.addText(pageElement["indicators"][idx]["metric_name"],
                {
                    x: 5.85, y: indicatorY - 0.025, w: 1, h: 0.15,
                    align: 'left', fontFace: 'Century Gothic', fontSize: 6, color:'#000000'
                }
            );
            indicatorCount++;
        }

        // minichart
        for( let i = 0; i < pageElement["miniCharts"].length; i++){
            slide.addImage({data: pageElement["miniCharts"][i]['image'], x: 0.25, y: currentY, h: miniChartHeight, w: 6.5});
            currentY += miniChartHeight
        }

        // response curve
        slide.addImage({data: pageElement["responseCurve"]['image'], x: 7, y: 1.5, h: 1.5, w: 2.75});
        let responseDataIdx = 0
        for (let stat in pageElement["responseCurve"]["data"]){
            slide.addText(stat,
                {
                    x: 7,y: 3.15 + 0.2 * responseDataIdx, h: 0.2, w: 1.5,
                    align: 'left', fontFace: 'Century Gothic', fontSize: 8,  color:'#000000'
                }
            );
            slide.addText(pageElement["responseCurve"]["data"][stat], 
                {
                    x: 8,y: 3.15+ 0.2 * responseDataIdx, h: 0.2, w: 1,
                    align: 'right', fontFace: 'Century Gothic', fontSize: 8, color:'#000000'
                }
            );
            responseDataIdx++;
        }
    }
    save(){
        this.pptx.writeFile(this.pptx_name);
    }
}
export default PPTXBuilder