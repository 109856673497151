<template>
  <v-container class="pt-0 pb-3" style="max-width: 100%">
    <v-row v-if="export_overlay">
      <v-col cols="12">
        <v-overlay :absolute="true" :value="export_overlay" :z-index="999">
          <v-card class="pa-3 overlay_card">
            <v-row justify="center" align="center" style="height: 100%; width: 100%">
              <v-col cols="auto">
                <v-progress-circular
                  indeterminate
                  size="300"
                  color="black"
                >
                  <v-row style="height: 100%; width: 100%;" align="center" justify="center">
                    <v-col cols="auto" align="center">
                      <span>Please Allow us a Few Minutes to Generate your Export</span>
                    </v-col>
                  </v-row>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="auto" class="pt-0">
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" cols="auto">
            <v-btn
              icon
              class="toolButton"
              color="#323232"
              :disabled="selected_level_val == available_level_vals[0]"
              @click="updateLevelVal(-1)"
            >
              <v-icon large>mdi-arrow-left-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col class="ma-0 pa-0" cols="auto">
            <v-select
              class="ma-0 pa-0"
              style="width: 400px"
              v-model="selected_level_val"
              :items="available_level_vals"
              hide-details
            >
              <template v-slot:selection="{ item }">
                <span class="graph_select_title">{{
                  item.replaceAll("__", " - ").replaceAll("_", " ")
                }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span class="graph_select_options">{{
                  item.replaceAll("__", " - ").replaceAll("_", " ")
                }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col class="ma-0 pa-0" cols="auto">
            <v-btn
              icon
              class="toolButton"
              color="#323232"
              :disabled="
                selected_level_val ==
                available_level_vals[available_level_vals.length - 1]
              "
              @click="updateLevelVal(1)"
            >
              <v-icon large>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pt-0">
        <div class="toolBar">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="axisLockChanged(lockVAxis ? false : true, 'vAxis')"
              >
                <v-icon large :color="lockVAxis ? '#949494' : '#000000'"
                  >mdi-arrow-vertical-lock</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Vertical Axis Lock"></TooltipDescription>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="axisLockChanged(lockHAxis ? false : true, 'hAxis')"
              >
                <v-icon large :color="lockHAxis ? '#949494' : '#000000'"
                  >mdi-arrow-horizontal-lock</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Horizontal Axis Lock"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="
                  finance_drawer = false;
                  toggle_indicators_drawer();
                "
              >
                <v-icon large :color="!indicators_drawer ? '#000000' : '#949494'"
                  >mdi-function</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Indicators Drawer"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="
                  finance_drawer = !finance_drawer;
                  toggle_indicators_drawer(false);
                "
              >
                <v-icon large :color="!finance_drawer ? '#000000' : '#949494'"
                  >mdi-finance</v-icon
                >
              </v-btn>
            </template>
            <TooltipDescription title="Response Curve"></TooltipDescription>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="exportCharts()"
                :disabled="export_overlay"
              >
                <v-icon large>mdi-download</v-icon>
              </v-btn>
            </template>
            <TooltipDescription title="Export Charts"></TooltipDescription>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="toolButton"
                color="#323232"
                v-on="on"
                @click="open_fullscreen_chart()"
              >
                <v-icon large>mdi-fullscreen</v-icon>
              </v-btn>
            </template>
            <TooltipDescription title="Fullscreen Mode"></TooltipDescription>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" style="position: relative">
      <!--Loading-->
      <v-col :cols="indicators_drawer || finance_drawer ? 9 : 12">
        <MainChart
          ref="mainChartComponent"
          :chartConfig="chartConfig"
          :mini_charts_list="mini_charts_list"
          :rawData="rawData"
          :isGraphVisible="graph_visible"
          :selected_metrics="selected_metrics"
          :dataset="dataset"
          @reload_graph="reload_graph"
          @updateLevelVal="updateLevelVal"
          @update_graph="update_graph"
          @update_selected_metrics="update_selected_metrics"
          @select_indicator="select_indicator"
          :key="mainChartComponentKey"
        />
      </v-col>
      <!--Finance Drawer-->
      <v-col cols="3" v-if="finance_drawer">
        <v-card
          :style="{
            height: screenHeight,
            'max-height': screenHeight + 'px',
          }"
          flat
        >
          <ResponseCurve ref="responseCurveComponent" :dashboard_data="dashboard_data" />
        </v-card>
      </v-col>

      <!--Indicators Drawer-->
      <v-col cols="3" v-if="indicators_drawer">
        <IndicatorsDrawer
          :selected_metric_ids="selected_metric_ids"
          :screen_height="screenHeight"
          @select_indicator="select_indicator"
          @remove_indicator="remove_indicator"
          @toggle_indicators_drawer="toggle_indicators_drawer"
        />
      </v-col>
    </v-row>
    <!-- OVERLAY -->
    <v-row class="ma-0 pa-0" v-if="fullScreenChartOverlay">
      <v-col cols="12">
        <v-overlay :value="fullScreenChartOverlay">
          <v-card style="height: 100%; width: 100%" class="pa-3">
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  icon
                  class="toolButton"
                  color="#323232"
                  :disabled="selected_level_val == available_level_vals[0]"
                  @click="updateLevelVal(-1)"
                >
                  <v-icon color="white" large>mdi-arrow-left-circle</v-icon>
                </v-btn>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-select
                  class="ma-0 pa-0"
                  style="width: 400px"
                  v-model="selected_level_val"
                  :items="available_level_vals"
                >
                  <template v-slot:selection="{ item }">
                    <span class="graph_select_title">{{
                      item.replaceAll("__", " - ").replaceAll("_", " ")
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="graph_select_options">{{
                      item.replaceAll("__", " - ").replaceAll("_", " ")
                    }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  icon
                  class="toolButton"
                  color="#323232"
                  :disabled="
                    selected_level_val ==
                    available_level_vals[available_level_vals.length - 1]
                  "
                  @click="updateLevelVal(1)"
                >
                  <v-icon color="white" large>mdi-arrow-right-circle</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="toolButton"
                      color="#323232"
                      v-on="on"
                      @click="axisLockChanged(lockHAxis ? false : true, 'hAxis')"
                    >
                      <v-icon large :color="lockHAxis ? '#949494' : '#FFFFFF'"
                        >mdi-arrow-horizontal-lock</v-icon
                      >
                    </v-btn>
                  </template>
                  <TooltipDescription title="Horizontal Axis Lock"></TooltipDescription>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="toolButton"
                      color="#323232"
                      v-on="on"
                      @click="axisLockChanged(lockVAxis ? false : true, 'vAxis')"
                    >
                      <v-icon large :color="lockVAxis ? '#949494' : '#FFFFFF'"
                        >mdi-arrow-vertical-lock</v-icon
                      >
                    </v-btn>
                  </template>
                  <TooltipDescription title="Vertical Axis Lock"></TooltipDescription>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                <v-btn
                  @click="
                    fullScreenChartOverlay = false;
                    reload_graph();
                  "
                  class="overlayButton"
                  icon
                >
                  <v-icon color="white" large>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <MainChart
                  ref="chartComponentFullScreen"
                  :chartConfig="fullScreenChartConfig"
                  :mini_charts_list="mini_charts_list"
                  :rawData="rawData"
                  :fullScreen="true"
                  :isGraphVisible="graph_visible"
                  :selected_metrics="selected_metrics"
                  :dataset="dataset"
                  @reload_graph="reload_graph"
                  @update_graph="update_graph"
                  @update_selected_metrics="update_selected_metrics"
                  @select_indicator="select_indicator"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//Demo Data
import user_setting_test from "@/test_data/user_profile_test.json";

// Configs
import chartConfig from "@/configs/Performance/performance_chart_config.json";
import indicator_list from "@/configs/Performance/available_indicators.json";

import ResponseCurve from "./PerformanceGraphComponents/ResponseCurve.vue";
import IndicatorsDrawer from "./PerformanceGraphComponents/IndicatorsDrawer.vue";
import MainChart from "./PerformanceGraphComponents/MainChart.vue";

import api_caller from "@/javascript/data_retrieval.js";
import PPTXBuilder from "@/javascript/pptxBuilder";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

import {
  format_data_to_gchart,
  calculate_exponential_moving_average,
  calculate_simple_moving_average,
  calculate_simple_moving_average_cross,
  calculate_bollinger_band,
  compute_minichart_volume,
  set_marker_line,
  calculate_average_true_range,
  calculate_keltner_channels,
  calculate_MACD,
} from "@/components/Performance/PerformanceStats/statistics.js";

import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

export default {
  name: "PerformanceStatsGraph",
  props: ["project_date_range", "selected_channel", "data_levels", "data_filters"],
  components: {
    ResponseCurve,
    IndicatorsDrawer,
    TooltipDescription,
    MainChart,
  },
  methods: {
    axisLockChanged: function (toggle, axis) {
      let tempConfig = JSON.parse(JSON.stringify(chartConfig));
      if (toggle) {
        const chart = this.$refs.mainChartComponent.$refs.mainChart.chartObject;
        var chartLayout = chart.getChartLayoutInterface();
        var chartBounds = chartLayout.getChartAreaBoundingBox();

        if (axis == "hAxis") {
          this.lockVAxis = false;
          this.lockHAxis = true;
          tempConfig.mainChartOptions["explorer"]["axis"] = "vertical";
          tempConfig.mainChartOptions["hAxis"]["viewWindow"] = {
            min: chartLayout.getHAxisValue(chartBounds.left),
            max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left),
          };
        } else {
          this.lockHAxis = false;
          this.lockVAxis = true;
          tempConfig.mainChartOptions["explorer"]["axis"] = "horizontal";
          tempConfig.mainChartOptions["vAxis"]["viewWindow"] = {
            min: 0,
            max: chartLayout.getVAxisValue(chartBounds.top),
          };
        }
      } else {
        this.lockHAxis = false;
        this.lockVAxis = false;
        tempConfig.mainChartOptions["explorer"]["axis"] = "horizontal";
        if (axis == "hAxis") {
          tempConfig.mainChartOptions["hAxis"]["viewWindow"] = {};
        } else {
          tempConfig["vAxis"]["viewWindow"] = { min: 0 };
        }
      }
      this.chartConfig = tempConfig;
    },
    switchChart: async function (channel) {
      if (!channel) {
        channel = {
          data_levels: ["channel"],
          data_query: [{ level: "channel", value: "tv" }],
        };
      }
      this.graph_visible = false;
      this.graph_export_ready = false;
      const token = await this.$auth.getTokenSilently();
      let date_range = {
        start_date: this.project_date_range["start_date"],
        end_date: this.project_date_range["end_date"],
      };

      let dashboard_data = await api_caller
        .get_dashboard_data(
          token,
          this.$project.get_val(),
          channel,
          date_range,
          this.data_filters
        )
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (!dashboard_data) {
        this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      } else {
        this.dashboard_data = dashboard_data;
        this.dataset = dashboard_data["data"];
      }

      if (this.selected_metrics.length == 0) {
        this.select_indicator(0);

        // setup preset indicator from user profile
        if (this.user_profile && this.user_profile["preset_metrics"]) {
          this.user_profile["preset_metrics"].forEach((metric) => {
            this.select_indicator(metric);
          });
        }
      }

      this.mini_charts_list = [];
      for (let row in this.selected_metrics) {
        if (row == 0) {
          this.selected_metrics[row]["visible"] = false;
        } else {
          this.selected_metrics[row]["visible"] = !this.selected_metrics[row]["visible"];
        }
      }
      for (let row of this.selected_metrics) {
        this.update_graph(row.metric_id);
        this.graph_visible = true;
      }

      let temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.reload_graph();
    },

    toggleBase: function () {
      let indicator = this.get_idx_indicator_selected(0);
      let jdx = indicator[0];
      indicator = indicator[1];
      this.selected_metrics[jdx]["visible"] = !indicator["visible"];

      let temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleBollinger: function (idx) {
      // add the new header column columns to the table header
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let data = 0;

      let type = indicator["params"][0];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        if (type === "keltner") {
          data = calculate_keltner_channels(
            this.dataset,
            "sales_attribution",
            indicator["metric_stat_headers"][0],
            indicator["metric_stat_headers"][1],
            indicator["metric_stat_headers"][2]
          );
        } else {
          data = calculate_bollinger_band(
            this.dataset,
            "sales_attribution",
            indicator["metric_stat_headers"][0],
            indicator["metric_stat_headers"][1],
            indicator["metric_stat_headers"][2]
          );
        }
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMovingAvg: function (idx) {
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let data = [];
      let type = indicator["params"][0];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        if (type === "ema") {
          let days = indicator["params"][1];
          data = calculate_exponential_moving_average(
            this.dataset,
            days,
            "sales_attribution",
            indicator["metric_stat_headers"]
          );
        } else if (type === "macd") {
          let dpoint = indicator["params"][1];
          data = calculate_MACD(this.dataset, dpoint, indicator["metric_stat_headers"]);
        } else {
          let days = indicator["params"][1];
          data = calculate_simple_moving_average(
            this.dataset,
            days,
            "sales_attribution",
            indicator["metric_stat_headers"]
          );
        }
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleAvgTrueRange: function (idx) {
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      let days = indicator["params"][0];

      let data = [];

      if (!indicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        data = calculate_average_true_range(
          this.dataset,
          days,
          "sales_attribution",
          indicator["metric_stat_headers"]
        );
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      let temp_chart_headers = this.visible_graph_header;
      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMovAvgCross: function (crossIdx) {
      let temp_chart_rows = [];
      let crossIndicator = this.get_idx_indicator_selected(crossIdx);
      let jdx = crossIndicator[0];
      crossIndicator = crossIndicator[1];
      let fast_ma = crossIndicator["params"][0];
      let slow_ma = crossIndicator["params"][1];

      if (!crossIndicator.visible) {
        this.selected_metrics[jdx]["visible"] = true;
        let data = calculate_simple_moving_average_cross(
          this.dataset,
          "sales_attribution",
          fast_ma,
          slow_ma,
          crossIndicator["metric_stat_headers"][0],
          crossIndicator["metric_stat_headers"][1],
          crossIndicator["metric_stat_headers"][2]
        );
        this.dataset = data;
        temp_chart_rows = format_data_to_gchart(data, this.visible_columns);
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      }
      temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;

      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMarkerLine: function (toggle, col) {
      let temp_chart_rows = [];
      let indicator = this.get_idx_indicator_selected(200);
      let jdx = indicator[0];
      indicator = indicator[1];

      if (toggle) {
        this.selected_metrics[jdx]["visible"] = !this.selected_metrics[jdx]["visible"];
      } else {
        let data = set_marker_line(
          this.dataset,
          "sales_attribution",
          col,
          indicator["metric_stat_headers"][1],
          indicator["metric_stat_headers"][0]
        );
        this.dataset = data;
      }
      temp_chart_rows = format_data_to_gchart(this.dataset, this.visible_columns);
      let temp_chart_headers = this.visible_graph_header;

      this.rawData = { cols: temp_chart_headers, rows: temp_chart_rows };
      this.chartConfig.mainChartOptions.colors = this.visible_graph_colors;
      this.chartConfig.mainChartOptions.series = this.graph_series_config;
    },

    toggleMiniGraph: function (idx) {
      let indicator = null;
      let miniChartWithHaxisHeightPct = "75%";
      let mainChartWithHaxisHeightPct = "95%";

      indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];

      if (!indicator.visible) {
        let tmp_chart_data = {};
        let headers = indicator["graph_header"];
        let chart_options = JSON.parse(JSON.stringify(this.chartConfig.miniChartOptions));

        if (idx == 100) {
          chart_options["vAxis"]["scaleType"] = "log";
        }

        // for roas indicator
        if (idx == 100) {
          tmp_chart_data = {
            cols: headers,
            rows: format_data_to_gchart(this.dataset, ["date", "roas"]),
          };
          chart_options["seriesType"] = "lines";
          chart_options["colors"] = ["#50A684"];
        } else if (idx == 101) {
          this.dataset = compute_minichart_volume(
            this.dataset,
            "spend",
            "spend_delta_volume",
            "spend_normalized"
          );
          headers.push({
            id: "bar_color",
            name: "Bar Color",
            role: "style",
            type: "string",
          });

          let rows = format_data_to_gchart(this.dataset, ["date", "spend"]);
          rows.forEach((row, idx) => {
            row["c"].push({
              f: null,
              v: this.dataset[idx]["spend_delta_volume"] >= 0 ? "green" : "red",
            });
          });

          tmp_chart_data = {
            cols: headers,
            rows: rows,
          };
        } else if (idx == 102) {
          this.dataset = compute_minichart_volume(
            this.dataset,
            "impressions",
            "impressions_delta_volume",
            "impressions_normalized"
          );

          headers.push({
            id: "bar_color",
            name: "Bar Color",
            role: "style",
            type: "string",
          });

          let rows = format_data_to_gchart(this.dataset, ["date", "impressions"]);
          rows.forEach((row, idx) => {
            row["c"].push({
              f: null,
              v: this.dataset[idx]["impressions_delta_volume"] >= 0 ? "green" : "red",
            });
          });

          tmp_chart_data = {
            cols: headers,
            rows: rows,
          };
        } else if (idx == 103) {
          tmp_chart_data = {
            cols: headers,
            rows: format_data_to_gchart(this.dataset, ["date", "cpm"]),
          };
          chart_options["seriesType"] = "lines";
          chart_options["colors"] = ["#426E86"];
        }
        this.selected_metrics[jdx]["visible"] = true;

        // when adding new minigraph hide the axi for the main graph
        // And if there are more than 1 mini graph, add the xAxis to the last mini graph
        this.chartConfig.mainChartOptions["hAxis"]["textPosition"] = "none";
        this.chartConfig.mainChartOptions["chartArea"]["height"] = "100%";
        if (this.mini_charts_list.length > 0) {
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["hAxis"][
            "textPosition"
          ] = "none";
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["chartArea"][
            "height"
          ] = "100%";
        }
        chart_options["chartArea"]["height"] = miniChartWithHaxisHeightPct;
        chart_options["title"] = this.selected_metrics[jdx]["metric_name"];
        chart_options["titlePosition"] = "in";
        this.mini_charts_loc[idx] = this.mini_charts_list.length;

        this.mini_charts_list.push({
          graph_id: "miniChart_" + this.mini_charts_list.length + 1,
          data: tmp_chart_data,
          config: chart_options,
        });
      } else {
        this.selected_metrics[jdx]["visible"] = false;
        let chart_loc = this.mini_charts_loc[idx];
        this.mini_charts_list.splice(chart_loc, 1);

        if (this.mini_charts_list.length > 0) {
          delete this.mini_charts_list[this.mini_charts_list.length - 1]["config"][
            "hAxis"
          ]["textPosition"];
          this.mini_charts_list[this.mini_charts_list.length - 1]["config"]["chartArea"][
            "height"
          ] = miniChartWithHaxisHeightPct;
        } else {
          delete this.chartConfig.mainChartOptions["hAxis"]["textPosition"];
          this.chartConfig.mainChartOptions["chartArea"][
            "height"
          ] = mainChartWithHaxisHeightPct;
        }

        for (let minichartidx of Object.keys(this.mini_charts_loc)) {
          if (minichartidx != idx && this.mini_charts_loc[minichartidx] > chart_loc) {
            this.mini_charts_loc[minichartidx] -= 1;
          }
        }
      }
    },

    select_indicator: function (idx) {
      let indicator = this.get_idx_indicator_available(idx);
      let jdx = indicator[0];
      indicator = indicator[1];
      this.available_metrics.splice(jdx, 1);
      this.selected_metrics.push(indicator);
      this.selected_metric_ids.push(indicator.metric_id);
      this.update_graph(idx);
    },

    remove_indicator: function (idx) {
      let indicator = this.get_idx_indicator_selected(idx);
      let jdx = indicator[0];
      indicator = indicator[1];
      if (indicator["visible"]) {
        this.update_graph(indicator["metric_id"]);
      }
      this.selected_metrics.splice(jdx, 1);
      this.selected_metric_ids.splice(jdx, 1);
      this.available_metrics.push(indicator);
      this.reload_graph();
    },

    update_selected_metrics: function (idx, toggle) {
      this.selected_metrics[idx]["visible"] = toggle;
    },

    toggle_indicators_drawer: function (toggle) {
      if (toggle == null) {
        this.indicators_drawer = !this.indicators_drawer;
      } else {
        this.indicators_drawer = toggle;
      }
      this.reload_graph();
    },

    update_graph: function (idx, col) {
      switch (true) {
        case idx == 0:
          this.toggleBase();
          break;
        case [1, 2, 6, 7, 8, 9, 10, 11, 12, 13, 17, 18].includes(idx):
          this.toggleMovingAvg(idx);
          break;
        case [4, 5, 15].includes(idx):
          this.toggleMovAvgCross(idx);
          break;
        case [16].includes(idx):
          this.toggleAvgTrueRange(idx);
          break;
        case [3, 14].includes(idx):
          this.toggleBollinger(idx);
          break;
        case idx == 200:
          this.toggleMarkerLine(null, col);
          break;
        case idx >= 100 && idx < 200:
          this.toggleMiniGraph(idx);
          break;
      }
      this.reload_graph();
    },

    get_idx_indicator_selected: function (idx) {
      let jdx = 0;
      for (let indicator of this.selected_metrics) {
        if (indicator["metric_id"] == idx) {
          return [jdx, indicator];
        }
        jdx += 1;
      }
      return -1;
    },

    get_idx_indicator_available: function (idx) {
      let jdx = 0;
      for (let indicator of this.available_metrics) {
        if (indicator["metric_id"] == idx) {
          return [jdx, indicator];
        }
        jdx += 1;
      }
    },

    reload_graph: function () {
      this.graph_export_ready = false;
      this.graph_visible = false;
      setTimeout(() => {this.graph_visible = true; this.graph_export_ready = true;}, 10);
    },

    open_fullscreen_chart: function () {
      this.fullScreenChartConfig = { ...this.chartConfig };
      this.fullScreenChartOverlay = true;
    },

    deepEqual: function (object1, object2) {
      if (object1 == null && object2 != null) {
        return false;
      }
      if (object1 != null && object2 == null) {
        return false;
      }
      if (object1 == null && object2 == null) {
        return true;
      }
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          (areObjects && !this.deepEqual(val1, val2)) ||
          (!areObjects && val1 !== val2)
        ) {
          return false;
        }
      }

      return true;
    },

    isObject: function (object) {
      return object != null && typeof object === "object";
    },

    updateLevelVal: function (indexShift) {
      let curIdx =
        this.available_level_vals.indexOf(this.selected_level_val) + indexShift;
      this.selected_level_val = this.available_level_vals[curIdx];

      // reset the main chart component key to force chart re-render
      this.mainChartComponentKey ++;
    },
    chartsLoaded: async function() {
      while(! this.graph_export_ready){
        await delay(1000);
        console.log('Graph Visible', this.graph_visible);
      }
      await delay(2000);
      return true;
    },
    exportCharts: async function () {
      this.export_overlay = true;

      let pptx_doc = new PPTXBuilder();

      // reset page status for export
      this.selected_level_val = this.available_level_vals[0];
      this.toggle_indicators_drawer(false);
      this.finance_drawer = true;
      await delay(3000);

      // chart = this.$refs["mainChart"].chartObject;
      let mainChartComp = this.$refs["mainChartComponent"];
      let responseCurveComp = this.$refs["responseCurveComponent"];

      for (let i = 0; i < this.available_level_vals.length; i++) {
        if (i > 0) {
          this.updateLevelVal(1);
        }
        // wait for the chart to render
        await this.chartsLoaded();
        mainChartComp = this.$refs["mainChartComponent"];
        responseCurveComp = this.$refs["responseCurveComponent"];
        let pageElement = {
          title: this.selected_level_val.slice(),
          indicators: [],
          mainChart: {},
          miniCharts: [],
          responseCurve: {},
        };

        for (let chartRef in mainChartComp.$refs) {
          let chartData = mainChartComp.$refs[chartRef];

          if (chartRef == "mainChart") {
            // setting up main chart data
            pageElement.mainChart["image"] = chartData.chartObject.getImageURI();
          } else if (chartData.length > 0) {
            // setting up mini chart data
            pageElement.miniCharts.push({
              image: chartData[0].chartObject.getImageURI(),
            });
          }
        }

        // setting up indicators data
        for (let indicator of this.selected_metrics) {
          if (indicator["visible"] == true && indicator["metric_id"] < 100) {
            let indicatorColor = Array.isArray(indicator["graph_color"])
              ? indicator["graph_color"][indicator["graph_color"].length - 1]
              : indicator["graph_color"];
            pageElement.indicators.push({
              metric_name: indicator["metric_name"],
              metric_color: indicatorColor,
            });
          }
        }
        pageElement.mainChart["indicators"] = pageElement.indicators;

        // setting up response curve data
        let responseCurveChart = responseCurveComp.$refs["responseCurve"].chartObject;
        pageElement.responseCurve = {
          image: responseCurveChart.getImageURI(),
          data: {
            cpm: responseCurveComp.reported_cpm,
            impression: responseCurveComp.reported_impr,
            roas: responseCurveComp.reported_roas,
            spend: responseCurveComp.reported_spend,
          },
        };
        pptx_doc.add_page(pageElement);
      }
      pptx_doc.build();
      pptx_doc.save();

      this.export_overlay = false;
    },
  },
  data: () => ({
    start_date: null,
    end_date: null,
    selected_level_val: null,
    available_level_vals: [],
    dataset: [],
    rawData: {},
    user_profile: user_setting_test,
    fullScreenChartConfig: {},
    chartConfig: chartConfig,
    fullScreenChartOverlay: false,
    graph_visible: false,

    indicators_drawer: false,
    finance_drawer: true,

    selected_metrics: [],
    selected_metric_ids: [],
    available_metrics: [],
    screenHeight: window.innerHeight * (window.innerHeight > 1000 ? 0.6 : 0.55),

    mini_charts_list: [],
    mini_charts_loc: {},
    chartEvents: {},

    lockVAxis: false,
    lockHAxis: false,
    export_overlay: false,

    graph_export_ready: false,

    dashboard_data: {},
    mainChartComponentKey: 0
  }),
  mounted() {
    this.available_metrics = [...indicator_list["indicators"]];

    this.start_date = this.project_date_range["start_date"];
    this.end_date = this.project_date_range["end_date"];
    this.available_level_vals = this.data_levels && this.data_levels["level_values"];
    if (!this.available_level_vals.includes(this.selected_level_val)) {
      this.selected_level_val = this.available_level_vals[0];
    }
  },
  watch: {
    selected_channel: {
      immediate: true,
      handler: function () {
        let channel_title = "";
        if (this.selected_channel != null) {
          for (let q of this.selected_channel["data_query"]) {
            channel_title += q["value"];
            channel_title += "__";
          }
          channel_title = channel_title.slice(0, -2);
          this.selected_level_val = channel_title;
        }
        this.reload_graph();
      },
      deep: true,
    },
    selected_level_val: {
      immediate: true,
      handler: function () {
        let channel = {
          data_levels: [],
          data_query: [],
        };
        let i = 0;
        if (this.selected_level_val != null) {
          for (let v of this.selected_level_val.split("__")) {
            channel["data_levels"].push(this.data_levels["selected_levels"][i]);
            channel["data_query"].push({
              level: this.data_levels["selected_levels"][i],
              value: v,
            });
            i++;
          }
          this.switchChart(channel);
        }
      },
      deep: true,
    },
    data_levels: {
      handler: function () {
        this.available_level_vals = this.data_levels && this.data_levels["level_values"];
        if (!this.available_level_vals.includes(this.selected_level_val)) {
          this.selected_level_val = this.available_level_vals[0];
        }
        this.reload_graph();
      },
      deep: true,
    },
    project_date_range: {
      immediate: false,
      handler: function () {
        this.start_date = this.project_date_range["start_date"];
        this.end_date = this.project_date_range["end_date"];
        this.updateLevelVal(1);
      },
      deep: true,
    },
  },
  computed: {
    visible_columns: function () {
      let visible = ["date"];
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["metric_stat_headers"])) {
          for (let h of stat["metric_stat_headers"]) {
            visible.push(h);
          }
        } else if (stat["visible"] == true) {
          visible.push(stat["metric_stat_headers"]);
        }
      }
      return visible;
    },
    visible_graph_header: function () {
      let visible_header = [
        {
          id: "date",
          name: "Date",
          role: "domain",
          type: "date",
        },
      ];
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["graph_header"])) {
          for (let h of stat["graph_header"]) {
            visible_header.push(h);
          }
        } else if (stat["visible"] == true) {
          visible_header.push(stat["graph_header"]);
        }
      }
      if (visible_header.length < 2) {
        visible_header.push({
          id: "blank",
          name: "Blank",
          role: "data",
          type: "number",
        });
      }
      return visible_header;
    },
    visible_graph_colors: function () {
      let visible_colors = [];

      if (this.visible_columns.length < 2) {
        visible_colors.push("white");
      }

      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        } else if (stat["visible"] == true && Array.isArray(stat["graph_header"])) {
          // skip all non data role column
          // this is the resolve the graph color shifting behavior
          stat["graph_header"].forEach((item, idx) => {
            if (item.role == "data") {
              visible_colors.push(stat["graph_color"][idx]);
            }
          });
        } else if (stat["visible"] == true) {
          visible_colors.push(stat["graph_color"]);
        }
      }
      return visible_colors;
    },
    graph_series_config: function () {
      let graph_series = {};
      let series_idx = 0;
      for (let stat of this.selected_metrics) {
        if (stat["metric_id"] >= 100 && stat["metric_id"] < 200) {
          continue;
        }
        if (stat["visible"] == true && Array.isArray(stat["graph_series_config"])) {
          for (let h of stat["graph_series_config"]) {
            graph_series[series_idx] = h;
            series_idx++;
          }
        } else if (stat["visible"] == true) {
          if (Array.isArray(stat["graph_header"])) {
            // skip all non data role column
            // this is the resolve the graph color shifting behavior
            let dataRoleHeader = stat["graph_header"].filter(
              (header) => header.role == "data"
            );
            series_idx += dataRoleHeader.length;
          } else {
            series_idx++;
          }
        }
      }
      return graph_series;
    },
  },
};
</script>

<style scoped>
::v-deep .v-select__selection.v-select__selection--comma {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 1.5em !important;
  line-height: 30px;
}
::v-deep .v-list-item__title {
  font-family: "Poppins" !important;
}

.toolBar {
  float: right;
}

.toolButton {
  padding: 0 32px;
  width: 50px;
  color: black;
}

::v-deep .v-overlay__content {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  top: 0;
}

.overlayButton {
  float: right;
}

.graph_select_title {
  line-height: 1.5em;
  font-family: "Poppins";
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(80%);
  text-align: left;
  overflow: hidden;
}
.graph_select_options {
  line-height: 1em;
  font-family: "Poppins";
  font-weight: 400 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: calc(100%);
  text-align: left;
  overflow: hidden;
}

.overlay_card {
  background: white;
  position: fixed;
  height: 80%;
  width: 100%;
}
</style>
